<template>
    <div class="SenderEmailAddressesView ViewWrapper">
        <clv-head-meta :title="$tc('entity Sender Email Address', 2)"></clv-head-meta>
        <view-header>
            <h1>{{ $tc('entity Sender Email Address', 2) }}</h1>
            <p>{{ $t('sender_email_address_view_subtitle') }}</p>
        </view-header>
        <view-container>
            <div class="row mt-4 mb-4">
                <div class="col-md-6">
                    <div class="gk-card">
                        <p class="size-5 weight-4">{{ $t('Email Addresses') }}</p>

                        <!-- LISTING LOADER -->
                        <div class="ElementLoading-Placeholder" v-if="fetching">
                            <element-loading :active="true" background-color="transparent"></element-loading>
                        </div>

                        <!-- EMPTY STATE 2020-07-19 -->
                        <simple-state-empty v-if="displayEmptyState">
                            <p class="mb-0 text-muted" slot="title">{{ $t('no_sender_email_addresses_1') }}</p>
                        </simple-state-empty>

                        <!-- ERROR STATE 2020-07-19 -->
                        <simple-state-error v-if="displayError" :error="error"></simple-state-error>

                        <!-- LISTING -->
                        <div class="row" v-if="displayListing">
                            <div class="col-12">
                                <div class="d-flex justify-content-start align-items-center flex-wrap pt-3 pb-3"
                                     v-for="(dataItem, dataItemIndex) in data" :index="dataItemIndex" :key="dataItem.id">
                                    <span>{{ dataItem.emailAddress }}</span>
                                    <span class="badge badge-pill badge-dark text-white ml-2">{{ $t(dataItem.metadata.awsVerificationStatus) }}</span>
                                    <span class="cursor-pointer small ml-2" @click="deleteSenderEmailAddress(dataItem.id)">{{ $t('common_action_remove') }}</span>
                                </div>
                            </div>
                        </div>

                        <!-- This view has no pagination. We expect a small number of entities. -->
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="gk-card">
                        <p class="size-5 weight-4">{{ $t('Add Email Address') }}</p>
                        <sender-email-address-creator v-on:create-success="refreshData"></sender-email-address-creator>
                    </div>
                </div>
            </div>
        </view-container>
    </div>
</template>

<script>
import { SenderEmailAddressService } from '@/common/services/api.service';
import GenericListingMixin from '@/store/generics/GenericListingMixin';
import SenderEmailAddressCreator from '@/modules/Creators/components/SenderEmailAddressCreator';

/**
 * SenderEmailAddress listing view.
 * (verified email addresses for Broadcasters)
 *
 * @author Dimitris Gkoulis
 */
export default {
    name: 'SenderEmailAddressesView',
    components: {
        SenderEmailAddressCreator
    },
    mixins: [
        GenericListingMixin('SenderEmailAddresses', 'senderEmailAddresses')
    ],
    beforeMount () {
        this.getData();
    },
    methods: {
        refreshData () {
            this.getData();
        },
        deleteSenderEmailAddress (id) {
            // Confirmation.
            let resp = confirm(this.$t('Delete Sender Email Address?'));
            if (!resp) {
                return;
            }

            // Perform the delete request.
            SenderEmailAddressService.deleteSenderEmailAddress(id).then(() => {
                this.toastDefault('Sender Email Address deleted');
                // Refresh listing.
                this.getData();
                // Update EntityQlu for this entity.
                // 2020-03-13-@future-reduce-scattered-calls.
                this.$store.dispatch('application/quotasFindOneEntityQlu', 'senderEmailAddress');
            }).catch((reason) => {
                this.toastErrorFromError(reason);
            });
        }
    }
};
</script>

<i18n>
{
    "en": {
        "Pending": "Pending",
        "Success": "Verified"
    },
    "el": {
        "Pending": "Εκκρεμής",
        "Success": "Επαληθευμένη"
    }
}
</i18n>
