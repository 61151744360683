<template>
    <div class="SenderEmailAddressCreator position-relative">
        <element-loading :active="loading"></element-loading>
        <el-form :model="fields"
                 :rules="fieldsRules"
                 ref="newInstanceForm"
                 :label-position="'top'"
                 class="cltlr-form-label-style-1"
                 @submit.native.prevent>
            <el-form-item :label="$t('Email Address')"
                          prop="emailAddress"
                          for="emailAddressGcSenderEmailAddressCreator">
                <el-input id="emailAddressGcSenderEmailAddressCreator"
                          v-model="fields.emailAddress"></el-input>
            </el-form-item>
            <el-form-item class="mt-2 mb-0">
                <!--suppress HtmlUnknownAttribute -->
                <div v-tooltip="applicationQuotasUiEntityQluVTooltipObject('senderEmailAddress')">
                    <button type="button"
                            class="btn btn-primary btn-block"
                            @click="submitForm"
                            :disabled="applicationQuotasUiEntityQluDisabled('senderEmailAddress')">{{ $t('Send Confirmation Email') }}</button>
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SenderEmailAddressService } from '@/common/services/api.service';
import ElValidationUtils from '@/common/utils/el-validation.utils';

/**
 * SenderEmailAddress Creator.
 *
 * @author Dimitris Gkoulis
 * @createdAt February 2020
 * @lastModifiedAt 14 July 2020
 */
export default {
    name: 'SenderEmailAddressCreator',
    data () {
        return {
            loading: false,
            fields: {
                emailAddress: ''
            },
            fieldsRules: {
                emailAddress: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(null, 255),
                    ElValidationUtils.emailValidator()
                ]
            }
        };
    },
    computed: {
        ...mapGetters('application', {
            applicationQuotasUiEntityQluDisabled: 'quotasUiEntityQluDisabled',
            applicationQuotasUiEntityQluVTooltipObject: 'quotasUiEntityQluVTooltipObject'
        })
    },
    methods: {
        saveInstance () {
            this.loading = true;
            const emailAddressTemp = this.fields.emailAddress;
            SenderEmailAddressService.createSenderEmailAddress(emailAddressTemp)
                .then(({ data }) => {
                    // Reset form.
                    this.resetForm();
                    this.toastDefault('toast_sender_email_address_created_1');
                    // Update EntityQlu for this entity.
                    // 2020-03-13-@future-reduce-scattered-calls.
                    this.$store.dispatch('application/quotasFindOneEntityQlu', 'senderEmailAddress');
                    // Emit data to receiver components.
                    this.$emit('create-success', data.id);
                })
                .catch((reason) => {
                    if (reason.dataDetail.startsWith('Sender Email Address is reserved')) {
                        this.toastError(this.$t('temp_sender_email_address_reserved', {
                            email: this.fields.emailAddress
                        }));
                    } else {
                        this.toastErrorFromError(reason);
                    }
                    this.$emit('create-error', true);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // Form Controls //////////
        submitForm () {
            this.loading = true;
            this.$refs['newInstanceForm'].validate((valid) => {
                if (valid) {
                    this.saveInstance();
                } else {
                    this.loading = false;
                    return false;
                }
            });
        },
        resetForm () {
            this.$refs['newInstanceForm'].resetFields();
        }
    }
};
</script>

<i18n>
{
    "en": {
        "temp_sender_email_address_reserved": "Sender Email Address {email} is reserved."
    },
    "el": {
        "temp_sender_email_address_reserved": "Η Διευθύνση Email Αποστολέα {email} είναι δεσμευμένη."
    }
}
</i18n>
